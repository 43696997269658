import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useQuery } from '@headway/shared/react-query';

export const getUseHeldChargesEventsQueryKey = (userId: number | undefined) => [
  'held-charges-events',
  userId,
];

export const useHeldChargesEvents = (
  userId: number | undefined,
  options: object = {}
) => {
  const queryKey = getUseHeldChargesEventsQueryKey(userId);
  return useQuery(
    queryKey,
    () => ProviderEventApi.getAppointmentsWithHeldCharges(userId!),
    {
      enabled: !!userId,
      staleTime: Infinity,
      ...options,
    }
  );
};
