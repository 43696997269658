import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useQuery } from '@headway/shared/react-query';

export const getUseRecoupEventsQueryKey = (userId: number | undefined) => [
  'recoup-events',
  userId,
];

export const useRecoupEvents = (
  userId: number | undefined,
  options: object = {}
) => {
  const queryKey = getUseRecoupEventsQueryKey(userId);
  return useQuery(queryKey, () => ProviderEventApi.getRecoupEvents(userId!), {
    enabled: !!userId,
    staleTime: Infinity,
    ...options,
  });
};

export const getUseOpenRecoupEventsQueryKey = (userId: number | undefined) => [
  'open-recoup-events',
  userId,
];

export const useOpenRecoupEvents = (
  userId: number | undefined,
  options: object = {}
) => {
  const queryKey = getUseOpenRecoupEventsQueryKey(userId);
  return useQuery(
    queryKey,
    () => ProviderEventApi.getRecoupEvents(userId!, { exclude_settled: true }),
    { enabled: !!userId, staleTime: Infinity, ...options }
  );
};
