import { ProviderSearchFilters } from '@headway/api/models/ProviderSearchFilters';
import { trackEvent } from '@headway/shared/utils/analytics';

export enum LandingPageInteraction {
  Location = 'Location',
  Concerns = 'Concerns',
}

export enum SearchEntry {
  AllTopics = 'All Topics',
  GetCareHeader = '[Global] Get Care Header',
  GetCareFooter = '[Global] Get Care Footer',
  LandingPageGetStartedCta = '[Landing page] Get Started CTA',
  LandingPageTopicCarousel = '[Landing page] Topic Carousel',
  LandingPageSearchName = '[Landing Page] Search Name',
  SearchGuidanceModal = 'Search Guidance Modal',
}

/**
 * An enum that captures where the clicked search result was rendered.
 *
 * LIST_VIEW: In the main set of search results on Agora.
 * MAP: On a provider search result marker in the Agora map view.
 */
export enum SearchResultClickContextSource {
  LIST_VIEW = 'list_view',
  MAP = 'map',
  SEARCH_NAME_LIST_VIEW = 'search_by_name',
}

/**
 * An enum that captures how a user interacts with a provider search result.
 *
 * TIME_SLOT: Clicking on a specific availability time-slot
 * MORE_AVAILABILITIES_BUTTON: Clicking on the "More" button to view more availability time-slots.
 * PROVIDER_CARD: Clicking anywhere else on the provider search result card.
 */
export enum SearchResultClickContextInteraction {
  TIME_SLOT = 'time_slot',
  MORE_AVAILABILITIES_BUTTON = 'more',
  PROVIDER_CARD = 'card',
}

type SearchContext = {
  ctaText?: string;
  interaction?: LandingPageInteraction;
  source?: SearchEntry;
};

type userExperimentMetadata = {
  name: string;
  variant: 'control' | 'treatment';
};

export const trackProviderSearchStarted = ({
  filters,
  context,
}: {
  context: SearchContext;
  filters?: Partial<ProviderSearchFilters>;
}): void => {
  trackEvent({
    name: 'Provider Search Started',
    properties: {},
  });
};

export const trackProviderSearchConfigured = ({
  filters,
}: {
  filters: Partial<ProviderSearchFilters>;
}): void => {
  trackEvent({
    name: 'Provider Search Configured',
    properties: {},
  });
};

export const trackProviderSearchCompleted = ({
  filters,
  context,
}: {
  filters: Partial<ProviderSearchFilters>;
  context: SearchContext;
}): void => {
  trackEvent({
    name: 'Provider Search Completed',
    properties: {},
  });
};

export const trackProviderSearchFilterUpdated = (
  filter: string,
  value: any,
  currentFilters: any,
  algoliaQueryID: string,
  patientUserId: number,
  experimentNames?: string[]
): void => {
  const updatedFilters: any = {
    [filter]: value,
  };

  trackEvent({
    name: 'Provider Search Filter Updated',
    properties: {
      algoliaQueryId: algoliaQueryID,
      patientUserId: patientUserId,
      experimentNames: experimentNames,
    },
  });
};

export const trackProviderSearchResultsViewed = (
  totalProviders: number,
  guidanceCardShown: boolean,
  urlStr: string,
  algoliaQueryID: string,
  pageNumber: number,
  countOfProviders: number,
  providerIds: number[],
  currentFilters: any,
  patientUserId: number,
  experimentNames?: string[]
): void => {
  trackEvent({
    name: 'Provider Search Results Viewed',
    properties: {
      algoliaQueryId: algoliaQueryID,
      patientUserId: patientUserId,
      experimentNames: experimentNames,
    },
    options: {
      sendToDatadog: true,
    },
  });
};

export const trackProviderSearchResultClicked = (
  pageNumber: number,
  providerId: number,
  absolutePosition: number,
  relativePosition: number,
  isMapOpen: boolean,
  clickContextSource: SearchResultClickContextSource,
  clickContextInteraction: SearchResultClickContextInteraction,
  patientUserId: number,
  experimentNames?: string[]
): void => {
  trackEvent({
    name: 'Provider Search Result Clicked',
    properties: {
      pageNumber,
      providerId,
      absolutePosition,
      relativePosition,
      clickContextSource,
      clickContextInteraction,
      experimentNames: experimentNames,
      patientUserId: patientUserId,
    },
    options: {
      sendToDatadog: true,
    },
  });
};

export const trackSearchByNameResultClicked = (
  providerId: number,
  clickContextSource: SearchResultClickContextSource
): void => {
  trackEvent({
    name: 'Provider Search Result Clicked',
    properties: {
      providerId,
      clickContextSource,
    },
  });
};
