import { Avatar, Button, Menu } from '@mui/material';
import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { formatPatientName } from '@headway/shared/utils/patient';
import { theme } from '@headway/ui/theme';

import AccountMenuContent from './AccountMenuContent';

interface AccountMenuProps {
  user: any;
  logout: () => void;
  impersonatingUser?: UserRead;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  user,
  logout,
  impersonatingUser,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        css={{
          borderRadius: '50%',
          padding: 0,
          minWidth: 0,
        }}
        aria-controls="user-menu"
        aria-haspopup="true"
        aria-expanded={!!anchorEl}
        data-testid="avatar-button"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        {...rest}
      >
        <span className="sr-only">Account menu</span>
        <Avatar
          css={{
            border: `2px solid ${theme.color.primaryBackground}`,
            ':hover': {
              border: `2px solid ${theme.color.primary}`,
            },
            background: theme.color.primaryBackground,
            color: theme.color.primary,
          }}
        >
          {formatPatientName(user, {
            firstInitial: true,
            lastInitial: true,
            initialsWithoutPeriodOrSpace: true,
          }) || null}
        </Avatar>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        css={{
          '.MuiList-root': {
            paddingTop: 0,
          },
        }}
      >
        <AccountMenuContent
          user={user}
          impersonatingUser={impersonatingUser}
          logout={logout}
          onClose={handleClose}
        />
      </Menu>
    </React.Fragment>
  );
};
