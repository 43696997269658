import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { UserRead } from '@headway/api/models/UserRead';
import { UserSmsOptOutType } from '@headway/api/models/UserSmsOptOutType';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NO_AT_SIGN_MATCH,
  YUP_NO_AT_SIGN_MESSAGE,
  YUP_PHONE_MATCH,
} from '@headway/shared/constants/format';
import { DELEGATED_BOOKING_CHANNELS } from '@headway/shared/utils/delegatedBooking';

import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../constants/auth';
import { SMS_CONSENT_COPY_HELIX } from '../../constants/sms';
import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import { RevealableTextField } from './RevealableTextField';

export interface CreateAccountFormValues {
  firstName: string;
  lastName: string;
  hasDisplayNameChecked: boolean;
  displayNameCheckedCount: number;
  displayFirstName: string;
  displayLastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  smsAgreementViewed: boolean;
  smsOptIn: boolean;
  phone: string;
}

interface CreateAccountFormProps {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
  onLoginButtonClick: () => void;
  existingUserDetails: Pick<
    UserRead,
    | 'firstName'
    | 'lastName'
    | 'displayFirstName'
    | 'displayLastName'
    | 'email'
    | 'phone'
    | 'smsAgreement'
    | 'smsOptOutTypes'
    | 'inviteChannel'
  >;
  registerUser: (
    {
      firstName,
      lastName,
      displayFirstName,
      displayLastName,
      email,
      password,
      smsAgreementViewed,
      smsOptIn,
      phone,
    }: CreateAccountFormValues,
    formikHelpers: FormikHelpers<CreateAccountFormValues>
  ) => Promise<void>;
}

export const createAccountFormSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Please provide your first name'),
  lastName: Yup.string().trim().required('Please provide your last name'),
  displayFirstName: Yup.string()
    .trim()
    .matches(YUP_NO_AT_SIGN_MATCH, YUP_NO_AT_SIGN_MESSAGE),
  displayLastName: Yup.string()
    .trim()
    .matches(YUP_NO_AT_SIGN_MATCH, YUP_NO_AT_SIGN_MESSAGE),
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Please provide an email address'),
  password: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
    )
    .max(
      MAX_PASSWORD_LENGTH,
      `Passwords cannot be more than ${MAX_PASSWORD_LENGTH} characters`
    )
    .required('Please provide a password'),
  confirmPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], "These passwords don't match"),
  phone: Yup.string().when('smsAgreementViewed', {
    is: true,
    then: Yup.string().when('smsOptIn', {
      is: true,
      then: Yup.string()
        .test(
          'required',
          'Phone number is required.',
          (val) => (val || '').replace(/[^0-9]/g, '').length > 0
        )
        .matches(YUP_PHONE_MATCH, 'Phone number must be 10 digits.'),
    }),
  }),
});

const CreateAccountFormImpl = (props: CreateAccountFormProps) => {
  const { existingUserDetails, registerUser, AuthStore, onLoginButtonClick } =
    props;

  const showSmsConsentForm =
    existingUserDetails &&
    !existingUserDetails.smsAgreement &&
    !!existingUserDetails.inviteChannel &&
    DELEGATED_BOOKING_CHANNELS.includes(existingUserDetails.inviteChannel);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: existingUserDetails?.firstName || '',
        lastName: existingUserDetails?.lastName || '',
        hasDisplayNameChecked: false,
        displayNameCheckedCount: 0,
        displayFirstName: existingUserDetails?.displayFirstName || '',
        displayLastName: existingUserDetails?.displayLastName || '',
        email: existingUserDetails?.email || '',
        smsAgreementViewed: showSmsConsentForm,
        smsOptIn: existingUserDetails?.smsOptOutTypes?.some((optOut) =>
          [UserSmsOptOutType.ALL, UserSmsOptOutType.TWILIO].includes(optOut)
        )
          ? false
          : true,
        phone: existingUserDetails?.phone || '',
        password: '',
        confirmPassword: '',
      }}
      validationSchema={createAccountFormSchema}
      onSubmit={registerUser}
    >
      {({ values, status, isSubmitting, setFieldValue }) => {
        return (
          <>
            <ModalContent>
              <Form id="create-account">
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: 'auto auto',
                    gap: theme.spacing.x2,
                    alignItems: 'start',
                    '& #first-last-helptext': {
                      gridColumn: '1 / -1',
                      gridRow: '2 / 3',
                    },
                  }}
                >
                  <span id="first-last-helptext">
                    <CaptionText color="gray">
                      Enter the name of the individual receiving care as it is
                      listed on the insurance card so we can process claims
                      smoothly.
                    </CaptionText>
                  </span>
                  <FormControl
                    aria-describedby="first-last-helptext"
                    name="firstName"
                    component={TextField}
                    label="Legal first name"
                  />
                  <FormControl
                    name="lastName"
                    aria-describedby="first-last-helptext"
                    component={TextField}
                    label="Legal last name"
                  />
                </div>
                <>
                  <FormControl
                    name="hasDisplayNameChecked"
                    component={Checkbox}
                    onChange={() => {
                      setFieldValue(
                        'displayNameCheckedCount',
                        values.displayNameCheckedCount + 1
                      );
                    }}
                  >
                    I use a name that is different from my legal name.
                  </FormControl>
                  {values.hasDisplayNameChecked && (
                    <>
                      <div
                        css={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gridTemplateRows: 'auto auto',
                          gap: theme.spacing.x2,
                          alignItems: 'start',
                          '& #display-name-helptext': {
                            gridColumn: '1 / -1',
                            gridRow: '2 / 3',
                          },
                        }}
                      >
                        <FormControl
                          name="displayFirstName"
                          aria-describedby="display-name-helptext"
                          component={TextField}
                          label="First name"
                        />

                        <FormControl
                          name="displayLastName"
                          aria-describedby="display-name-helptext"
                          component={TextField}
                          label="Last name"
                        />
                        <span id="display-name-helptext">
                          <CaptionText color="gray">
                            This how you will be addressed by Headway and your
                            provider.
                          </CaptionText>
                        </span>
                      </div>
                    </>
                  )}
                </>
                <FormControl
                  name="email"
                  component={TextField}
                  label="Email"
                  disabled={!!existingUserDetails?.email}
                  autoComplete="email"
                />

                <FormControl
                  name="password"
                  component={RevealableTextField}
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                />
                <FormControl
                  name="confirmPassword"
                  component={RevealableTextField}
                  label="Confirm password"
                  type="password"
                  autoComplete="new-password"
                />
                {status ? (
                  <GuidanceCard variant={status.variant}>
                    {status.detail}
                  </GuidanceCard>
                ) : null}
                {showSmsConsentForm && (
                  <>
                    <FormControl name="smsOptIn" component={Checkbox}>
                      {SMS_CONSENT_COPY_HELIX}
                    </FormControl>
                    <FormControl
                      name="phone"
                      component={PhoneNumberField}
                      disabled={!values.smsOptIn}
                      label="Phone"
                    />
                  </>
                )}
              </Form>
            </ModalContent>
            <ModalFooter>
              <Button
                onPress={onLoginButtonClick}
                size="large"
                variant="secondary"
              >
                Log in instead
              </Button>
              <Button
                form="create-account"
                type="submit"
                size="large"
                variant="primary"
                disabled={isSubmitting}
              >
                {AuthStore.user && AuthStore.user.email
                  ? 'Complete your account'
                  : 'Create account'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};

export const CreateAccountForm = withStores(CreateAccountFormImpl);
