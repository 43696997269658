/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum InvoiceLineItemType {
    PATIENT_RESPONSIBILITY_CALCULATION = 'PATIENT_RESPONSIBILITY_CALCULATION',
    CANCELLATION_FEE = 'CANCELLATION_FEE',
    MANUAL_BALANCE_ADJUSTMENT = 'MANUAL_BALANCE_ADJUSTMENT',
    PAYMENT_WAIVED = 'PAYMENT_WAIVED',
    PATIENT_PAYMENT = 'PATIENT_PAYMENT',
    PATIENT_REFUND = 'PATIENT_REFUND',
    CARD_FEES = 'CARD_FEES',
    LINE_ITEM_NEGATION = 'LINE_ITEM_NEGATION',
    WRITE_OFF = 'WRITE_OFF',
    PROVIDER_INCENTIVE = 'PROVIDER_INCENTIVE',
    PROVIDER_PAYOUT = 'PROVIDER_PAYOUT',
    RESPONSIBILITY_CHANGE = 'RESPONSIBILITY_CHANGE',
    PAYMENT = 'PAYMENT',
    SELF_PAY_FEES = 'SELF_PAY_FEES',
    ADJUSTMENT = 'ADJUSTMENT',
    FEES = 'FEES',
    PAYMENT_REVERSAL = 'PAYMENT_REVERSAL'
}


export const InvoiceLineItemTypeMetadata: EnumMetadata<InvoiceLineItemType> = {
    name: 'InvoiceLineItemType',
    values: {
            PATIENT_RESPONSIBILITY_CALCULATION: 'PATIENT_RESPONSIBILITY_CALCULATION',
            CANCELLATION_FEE: 'CANCELLATION_FEE',
            MANUAL_BALANCE_ADJUSTMENT: 'MANUAL_BALANCE_ADJUSTMENT',
            PAYMENT_WAIVED: 'PAYMENT_WAIVED',
            PATIENT_PAYMENT: 'PATIENT_PAYMENT',
            PATIENT_REFUND: 'PATIENT_REFUND',
            CARD_FEES: 'CARD_FEES',
            LINE_ITEM_NEGATION: 'LINE_ITEM_NEGATION',
            WRITE_OFF: 'WRITE_OFF',
            PROVIDER_INCENTIVE: 'PROVIDER_INCENTIVE',
            PROVIDER_PAYOUT: 'PROVIDER_PAYOUT',
            RESPONSIBILITY_CHANGE: 'RESPONSIBILITY_CHANGE',
            PAYMENT: 'PAYMENT',
            SELF_PAY_FEES: 'SELF_PAY_FEES',
            ADJUSTMENT: 'ADJUSTMENT',
            FEES: 'FEES',
            PAYMENT_REVERSAL: 'PAYMENT_REVERSAL'
    }
}
