import { isDuplicateRequestError } from './query';

export const getSignupErrorMessage = (
  err?: AnyTS4TryCatchUnknownError,
  email?: string
): { variant: 'error' | 'warning'; detail: string } | null => {
  if (!err) {
    return null;
  }
  if (isDuplicateRequestError(err)) {
    if (err.response?.data?.detail === 'VERIFICATION_REQUIRED') {
      return {
        variant: 'warning',
        detail: `Looks like you already have an account with us. We’ve sent a verification email to ${email}. Please check your inbox and follow links in the email to continue logging in.`,
      };
    }
    if (err.response?.data?.detail === 'ONBOARDING_REQUIRED') {
      return {
        variant: 'warning',
        detail: `Looks like you already have an account with us. We’ve sent an onboarding email to ${email}. Please check your inbox and follow links in the email to continue logging in.`,
      };
    }
  }
  return {
    variant: 'error',
    detail: 'Looks like you already have an account with us. Log in now.',
  };
};
