/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserSmsOptOutType {
    TWILIO = 'TWILIO',
    ALL = 'ALL',
    PROM = 'PROM',
    FEEDBACK = 'FEEDBACK'
}


export const UserSmsOptOutTypeMetadata: EnumMetadata<UserSmsOptOutType> = {
    name: 'UserSmsOptOutType',
    values: {
            TWILIO: 'TWILIO',
            ALL: 'ALL',
            PROM: 'PROM',
            FEEDBACK: 'FEEDBACK'
    }
}
