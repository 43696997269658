import moment from 'moment';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';

import { getVisibleAvailabilityEndDate } from './providerAvailabilityUtils';

const ADDITIONAL_BUFFER_HOURS = 6;

// gemini.link(mamba/app/shared/helpers/delegated_booking_helper.py)
export const INTERNAL_DELEGATED_BOOKING_CHANNELS = [
  UserInviteChannel.PHYPA_DELEGATED_BOOKING,
  UserInviteChannel.ADMIN_PHONE,
  UserInviteChannel.ANTHEM_CASE_MANAGEMENT_PHONE_LINE,
  UserInviteChannel.HCSC_CASE_MANAGEMENT_PHONE_LINE,
  UserInviteChannel.ALLIED_PHYSICIANS_PHONE_LINE,
  UserInviteChannel.REGENCE_CASE_MANAGEMENT_PHONE_LINE,
  UserInviteChannel.PSYCHOLOGY_TODAY_PHONE_LINE,
];

export const DELEGATED_BOOKING_CHANNELS = [
  ...INTERNAL_DELEGATED_BOOKING_CHANNELS,
  UserInviteChannel.BOOKING_ASSISTANT,
];
// gemini.endlink

export const getSlugFromProviderURL = (url: string): string | undefined => {
  const parsedUrl = new URL(url);
  const pathName = parsedUrl.pathname;
  const providerIndex = pathName.indexOf('/providers/');
  if (providerIndex !== -1) {
    const slug = pathName
      .substring(providerIndex + '/providers/'.length)
      .split('/')[0];
    return slug;
  }
};

export const getDelegatedBookingStartEndDates = (
  daysOffset: number
): { startDate: Date; endDate: Date } => {
  const now = moment(new Date()).startOf('hour');
  const defaultStartDate = now.add(ADDITIONAL_BUFFER_HOURS, 'hours');

  const startDateWithOffset = defaultStartDate.add(daysOffset, 'days');

  const startDate =
    defaultStartDate.toDate() > startDateWithOffset.toDate()
      ? defaultStartDate.toDate()
      : startDateWithOffset.toDate();
  const endDate = getVisibleAvailabilityEndDate(startDate);
  return { startDate, endDate };
};

// gemini.link(mamba/app/shared/constants/provider.py)
const CANCELLATION_BUFFER_HOURS = 3;
const DEFAULT_CANCELLATION_CUTOFF_HOURS = 24;
const SHORTENED_CANCELLATION_BUFFER_HOURS = 1;
// gemini.endlink

export const getCancelationCutoffHours = (provider: ProviderRead) => {
  return provider.cancellationCutoffHours || DEFAULT_CANCELLATION_CUTOFF_HOURS;
};

export const getAutoCancelationCutoffHours = (
  provider: ProviderRead,
  showShortenedBuffer: boolean
) => {
  return (
    getCancelationCutoffHours(provider) +
    (showShortenedBuffer
      ? SHORTENED_CANCELLATION_BUFFER_HOURS
      : CANCELLATION_BUFFER_HOURS)
  );
};
