import styled from '@emotion/styled';
import { SxProps } from '@mui/material';
import React from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { PatientBillingEventRead } from '@headway/api/models/PatientBillingEventRead';
import { useFlag } from '@headway/feature-flags/react';
import { Banner } from '@headway/helix/Banner';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import { PATIENTS_TO_HOLD_ON_AUTOMATED_CHARGING } from '@headway/shared/FeatureFlags/flagNames';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';
import { A11yModalButton } from '@headway/ui/a11y';
import {
  ForethoughtButton,
  ForethoughtContext,
  WidgetState,
} from '@headway/ui/Forethought';
import { MarketConsumer } from '@headway/ui/providers/MarketProvider';

import { useBillingEvents } from '../../contexts';
import { useHeldChargesEvents } from '../../hooks/useHeldChargesEvents';
import { useRecoupEvents } from '../../hooks/useRecoupEvents';
import { useRouter } from '../../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import { getOutstandingBalanceDaysPastDue } from '../../utils/providerAppointmentUtils';
import { CreateAccountModal } from '../Auth/CreateAccountModal';
import ForgotPasswordModal from '../Auth/ForgotPasswordModal';
import { LoginModal } from '../Auth/LoginModal';
import { ResetPasswordModal } from '../Auth/ResetPasswordModal';
import Alert from './Alert';
import { Footer } from './Footer';
import { Header } from './Header';

export enum PageWrapperLayout {
  Default,
  FullPageAuth,
}

const ContentWrapper = styled.main({
  width: '100%',
  minHeight: '70vh', // prevent short content from pulling the Footer up
});

type PageWrapperImplProps = React.ComponentProps<typeof Header> & {
  a11yModalSx?: SxProps;
  AuthStore: IAuthStore;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideA11yModal?: boolean;
  hideZendesk?: boolean;
  className?: string;
  UiStore: IUiStore;
  children: React.ReactNode;
  layout?: PageWrapperLayout;
};

function PageWrapperImpl(props: PageWrapperImplProps) {
  const {
    a11yModalSx,
    AuthStore,
    children,
    hideHeader,
    hideFooter,
    hideZendesk,
    hideA11yModal,
    UiStore,
    className,
    ...rest
  } = props;

  const router = useRouter();

  const user = AuthStore.user;

  const isRegisteredAndVerifiedUser =
    getVerificationStatus(user) ===
    UserVerificationStatus.REGISTERED_AND_VERIFIED;

  const patientToHoldOnAutomatedCharging = useFlag(
    PATIENTS_TO_HOLD_ON_AUTOMATED_CHARGING,
    false
  );

  const { data: allRecoupEvents = [] } = useRecoupEvents(user?.id, {
    enabled: !!(user?.id && isRegisteredAndVerifiedUser),
  });
  const { data: eventsWithHeldCharges = [] } = useHeldChargesEvents(user?.id, {
    enabled: !!(
      user?.id &&
      isRegisteredAndVerifiedUser &&
      patientToHoldOnAutomatedCharging
    ),
  });

  const {
    partitionedBillingEvents: { needsAttention: billingEventsAwaitingAction },
  } = useBillingEvents();

  const globalCssOptOutByRoute = shouldOptOutOfGlobalCss(router.pathname)
    ? ''
    : 'legacy-global-css';

  return (
    <>
      <a
        href="#main-content"
        className="bg-system-green text-system-white z-popover absolute -top-[3em] left-0 p-4 leading-none duration-200 ease-in-out focus:top-0"
      >
        Skip to main content
      </a>
      <BillingBanners
        billingEventsAwaitingAction={billingEventsAwaitingAction}
        eventsWithHeldCharges={eventsWithHeldCharges}
        allRecoupEvents={allRecoupEvents}
      />
      {!hideHeader && <Header {...rest} />}
      <ContentWrapper
        id="main-content"
        className={(className + ' ' + globalCssOptOutByRoute).trim()}
      >
        {children}
      </ContentWrapper>
      {!hideFooter && (
        <MarketConsumer>
          {({ liveMarkets }) => (
            <Footer markets={liveMarkets} userId={user?.id} />
          )}
        </MarketConsumer>
      )}
      {AuthStore.loginModalOpen && <LoginModal />}
      {AuthStore.signupModalOpen && <CreateAccountModal />}
      {AuthStore.forgotPasswordModalOpen && <ForgotPasswordModal />}
      {AuthStore.resetPasswordModalOpen && <ResetPasswordModal />}
      <Alert />
      {!hideA11yModal && (
        <>
          <ForethoughtContext.Consumer>
            {({ widgetState }) => (
              <>
                {widgetState === WidgetState.Closed && (
                  <A11yModalButton
                    sx={{
                      bottom: 0,
                      height: 46,
                      margin: '24px 28px',
                      position: 'fixed',
                      right: 0,
                      width: 46,
                      zIndex: theme.layers.base + 1,
                      ...a11yModalSx,
                    }}
                  />
                )}
              </>
            )}
          </ForethoughtContext.Consumer>
          {isRegisteredAndVerifiedUser && (
            <ForethoughtButton positionOffset={68} sx={a11yModalSx} />
          )}
        </>
      )}
    </>
  );
}

type BillingBannersProps = {
  billingEventsAwaitingAction?: PatientBillingEventRead[];
  eventsWithHeldCharges?: ConcreteProviderEventRead[];
  allRecoupEvents?: ConcreteProviderEventRead[];
};

export const BillingBanners = ({
  billingEventsAwaitingAction,
  eventsWithHeldCharges,
  allRecoupEvents,
}: BillingBannersProps) => {
  const patientToHoldOnAutomatedCharging = useFlag(
    PATIENTS_TO_HOLD_ON_AUTOMATED_CHARGING,
    false
  );
  const isOutstandingBalanceOverdue =
    !!allRecoupEvents?.length &&
    getOutstandingBalanceDaysPastDue(allRecoupEvents) > 0;

  return billingEventsAwaitingAction?.length ? (
    <Banner variant="error">
      <span className="flex flex-wrap items-center justify-center gap-4 text-center">
        {billingEventsAwaitingAction?.length === 1 ? (
          <>
            1 transaction cannot be processed normally. Please pay it as soon as
            possible or it may affect your ability to attend sessions.
          </>
        ) : (
          <>
            {billingEventsAwaitingAction.length} transactions cannot be
            processed normally. Please pay them as soon as possible or it may
            affect your ability to attend sessions.
          </>
        )}
        <LinkButton variant="link" href="/billing">
          Pay now
        </LinkButton>
      </span>
    </Banner>
  ) : (
    patientToHoldOnAutomatedCharging &&
      (eventsWithHeldCharges?.length ?? 0) > 0 && (
        <Banner variant={isOutstandingBalanceOverdue ? 'error' : 'warning'}>
          <span className="flex flex-wrap items-center justify-center gap-2 text-center md:gap-4">
            You have an outstanding balance
            <LinkButton variant="link" href="/account">
              View balance
            </LinkButton>
          </span>
        </Banner>
      )
  );
};

/**
 * A function to determine if a route should be opted out of the global css found in misc.scss.
 * Eventually we want to remove this CSS but we need to do it in a way that doesn't break the app.
 */
const optOutRoutes = new Set(['/account']);

function shouldOptOutOfGlobalCss(pathname: string) {
  return optOutRoutes.has(pathname);
}

export const PageWrapper = withStores(PageWrapperImpl);
