import { Divider, MenuItem } from '@mui/material';
import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { useAppointmentReadiness } from '@headway/shared/hooks/useAppointmentReadiness';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import {
  formatPatientName,
  shouldDisplayAccountNotificationBadge,
  shouldDisplayBenefitNotificationBadge,
} from '@headway/shared/utils/patient';
import { theme } from '@headway/ui/theme';

import { useBillingEvents } from '../../contexts';
import { useRouter } from '../../hooks/useRouter';
import { Link } from '../Link';
import { NotificationBadge } from './NotificationBadge';

type AccountMenuContentProps = {
  user: any;
  impersonatingUser?: UserRead;
  logout: () => void;
  onClose: () => void;
};

const AccountMenuContent = ({
  user,
  impersonatingUser,
  logout,
  onClose,
}: AccountMenuContentProps) => {
  const router = useRouter();

  const { data: appointmentReadiness } = useAppointmentReadiness(user.id);

  const {
    data: shouldShowAnthemEAPExperience,
    isLoading: isLoadingShouldShowAnthemEAPExperience,
  } = useShouldShowAnthemEAPExperience(user.id, undefined, undefined);

  const {
    partitionedBillingEvents: { needsAttention: billingEventsAwaitingAction },
  } = useBillingEvents();

  const showBillingNotificationBadge =
    !!billingEventsAwaitingAction.length ||
    appointmentReadiness?.paymentMethod?.length;

  const showAccountNotificationBadge =
    appointmentReadiness &&
    shouldDisplayAccountNotificationBadge(appointmentReadiness);

  const showBenefitsNotificationBadge =
    appointmentReadiness &&
    shouldDisplayBenefitNotificationBadge({
      appointmentReadiness,
    });

  const isImpersonator =
    !!impersonatingUser && impersonatingUser.id !== user.id;
  return (
    <>
      <div css={{ padding: theme.space.base }} tabIndex={-1}>
        {formatPatientName(user) ? (
          <div
            css={{
              fontFamily: theme.fontFamily.brandText,
              fontSize: theme.fontSize.base,
              color: theme.color.primary,
              marginBottom: theme.space.xs,
            }}
          >
            {formatPatientName(user)}
          </div>
        ) : null}
        <div
          css={{
            fontFamily: theme.fontFamily.postGrotesk,
          }}
        >
          {user.email}
        </div>
      </div>
      <Divider css={{ marginBottom: theme.space.xs }} />
      <MenuItem component={Link} href="/account">
        Account
        <div className="self-start">
          <NotificationBadge hidden={!showAccountNotificationBadge} />
        </div>
      </MenuItem>
      <MenuItem component={Link} href="/benefits">
        Benefits
        <div className="self-start">
          <NotificationBadge hidden={!showBenefitsNotificationBadge} />
        </div>
      </MenuItem>
      <MenuItem component={Link} href="/appointments/upcoming">
        Appointments
      </MenuItem>
      {!shouldShowAnthemEAPExperience &&
        !isLoadingShouldShowAnthemEAPExperience && (
          <MenuItem component={Link} href="/billing">
            Billing
            <div className="self-start">
              <NotificationBadge hidden={!showBillingNotificationBadge} />
            </div>
          </MenuItem>
        )}
      <MenuItem component={Link} href="/messages">
        Messages
      </MenuItem>
      <MenuItem component={Link} href="/">
        Find a provider
      </MenuItem>
      <Divider className="mb-2" />
      <MenuItem
        component={(props: any) => (
          <Link {...props} target="_blank" rel="noreferrer" />
        )}
        href="/support"
      >
        Help Center
      </MenuItem>
      <Divider />
      {isImpersonator && (
        <MenuItem
          onClick={async () => {
            await AuthApi.undoImpersonateUser();

            // changes won't propagate properly unless we fully reload
            router.push('/');
            window.location.reload();
          }}
        >
          Stop Spoofing
        </MenuItem>
      )}
      <MenuItem tabIndex={0} onClick={logout}>
        Log Out
      </MenuItem>
    </>
  );
};

export default AccountMenuContent;
