/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserInviteChannel {
    PROVIDER_PORTAL = 'PROVIDER_PORTAL',
    AUTOBOOK_HC_REFERRAL = 'AUTOBOOK_HC_REFERRAL',
    ADMIN_HC_REFERRAL = 'ADMIN_HC_REFERRAL',
    ADMIN_PHONE = 'ADMIN_PHONE',
    SMS = 'SMS',
    ZOCDOC = 'ZOCDOC',
    HEALTHCARE_REFERRAL = 'HEALTHCARE_REFERRAL',
    DOCASAP = 'DOCASAP',
    PHYPA_DELEGATED_BOOKING = 'PHYPA_DELEGATED_BOOKING',
    ZELIS_HCSC = 'ZELIS_HCSC',
    ADMIN_KAISER_PERMANENTE = 'ADMIN_KAISER_PERMANENTE',
    ANTHEM_BANK_OF_AMERICA_EAP_LANDING_PAGE = 'ANTHEM_BANK_OF_AMERICA_EAP_LANDING_PAGE',
    ANTHEM_CASE_MANAGEMENT_PHONE_LINE = 'ANTHEM_CASE_MANAGEMENT_PHONE_LINE',
    HCSC_CASE_MANAGEMENT_PHONE_LINE = 'HCSC_CASE_MANAGEMENT_PHONE_LINE',
    ALLIED_PHYSICIANS_PHONE_LINE = 'ALLIED_PHYSICIANS_PHONE_LINE',
    REGENCE_CASE_MANAGEMENT_PHONE_LINE = 'REGENCE_CASE_MANAGEMENT_PHONE_LINE',
    BOOKING_ASSISTANT = 'BOOKING_ASSISTANT',
    SIMPLE_REFER = 'SIMPLE_REFER',
    PSYCHOLOGY_TODAY_PHONE_LINE = 'PSYCHOLOGY_TODAY_PHONE_LINE'
}


export const UserInviteChannelMetadata: EnumMetadata<UserInviteChannel> = {
    name: 'UserInviteChannel',
    values: {
            PROVIDER_PORTAL: 'PROVIDER_PORTAL',
            AUTOBOOK_HC_REFERRAL: 'AUTOBOOK_HC_REFERRAL',
            ADMIN_HC_REFERRAL: 'ADMIN_HC_REFERRAL',
            ADMIN_PHONE: 'ADMIN_PHONE',
            SMS: 'SMS',
            ZOCDOC: 'ZOCDOC',
            HEALTHCARE_REFERRAL: 'HEALTHCARE_REFERRAL',
            DOCASAP: 'DOCASAP',
            PHYPA_DELEGATED_BOOKING: 'PHYPA_DELEGATED_BOOKING',
            ZELIS_HCSC: 'ZELIS_HCSC',
            ADMIN_KAISER_PERMANENTE: 'ADMIN_KAISER_PERMANENTE',
            ANTHEM_BANK_OF_AMERICA_EAP_LANDING_PAGE: 'ANTHEM_BANK_OF_AMERICA_EAP_LANDING_PAGE',
            ANTHEM_CASE_MANAGEMENT_PHONE_LINE: 'ANTHEM_CASE_MANAGEMENT_PHONE_LINE',
            HCSC_CASE_MANAGEMENT_PHONE_LINE: 'HCSC_CASE_MANAGEMENT_PHONE_LINE',
            ALLIED_PHYSICIANS_PHONE_LINE: 'ALLIED_PHYSICIANS_PHONE_LINE',
            REGENCE_CASE_MANAGEMENT_PHONE_LINE: 'REGENCE_CASE_MANAGEMENT_PHONE_LINE',
            BOOKING_ASSISTANT: 'BOOKING_ASSISTANT',
            SIMPLE_REFER: 'SIMPLE_REFER',
            PSYCHOLOGY_TODAY_PHONE_LINE: 'PSYCHOLOGY_TODAY_PHONE_LINE'
    }
}
