import { AlertColor } from '@mui/material';
import React, { useEffect } from 'react';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { Modal } from '@headway/helix/Modal';
import { useMutation } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';

import { useRouter } from '../../hooks/useRouter';
import { IAuthStore, withStores } from '../../stores/withStores';
import ForgetPasswordForm from './ForgotPasswordForm';

interface ForgotPasswordModalProps {
  AuthStore: IAuthStore;
}

const ForgotPasswordModalImpl: React.FC<ForgotPasswordModalProps> = ({
  AuthStore,
}) => {
  const router = useRouter();
  const mutation = useMutation(async (email: string) => {
    return await AuthApi.sendPasswordResetEmail({ email });
  });

  const message =
    mutation.status === 'error'
      ? {
          text: 'We couldn’t send you an email. Double-check your email address and try again in a few minutes.',
          color: 'error' as AlertColor,
        }
      : mutation.status === 'success'
      ? {
          text: `We’ve sent an email to ${mutation.variables} with instructions to reset your password. If you don’t see it after a few minutes, double-check your spam folder and try again.`,
          color: 'success' as AlertColor,
        }
      : null;

  const handleClose = () => {
    AuthStore.closeForgotPasswordModal();
    const gatedRedirectLocation = AuthStore.gatedRedirectLocation;
    if (gatedRedirectLocation) {
      AuthStore.gatedRedirectLocation = undefined;
      router.push(gatedRedirectLocation);
    }
  };

  useEffect(() => {
    trackPageView({ name: 'Forgot Password Modal', properties: {} });
  }, []);

  return (
    <Modal isOpen={true} onDismiss={handleClose} title="Reset your password">
      <ForgetPasswordForm
        handleSubmit={async (values, actions) => {
          await mutation.mutateAsync(values.email);
          actions.setSubmitting(false);
        }}
        handleClose={handleClose}
        message={message}
        AuthStore={AuthStore}
      />
    </Modal>
  );
};

const ForgotPasswordModal = withStores(ForgotPasswordModalImpl);

export default ForgotPasswordModal;
