import { Link } from '@headway/helix/Link';

export const SMS_CONSENT_COPY_HELIX = (
  <span>
    I have reviewed Headway's SMS/Text Message{' '}
    <Link
      href={`https://headway.co/legal/terms`}
      target="_blank"
      rel="noopener noreferrer"
    >
      notification terms
    </Link>{' '}
    and expressly consent to receive SMS/Text Messages from Headway
  </span>
);

export const SMS_CONSENT_COPY = (
  <span>
    I have reviewed Headway's SMS/Text Message{' '}
    <a
      href={`https://headway.co/legal/terms`}
      target="_blank"
      rel="noopener noreferrer"
    >
      notification terms
    </a>{' '}
    and expressly consent to receive SMS/Text Messages from Headway
  </span>
);
