/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum InvoiceLineItemSource {
    INITIAL_CONFIRMATION = 'INITIAL_CONFIRMATION',
    CPT_CODE_CHANGE = 'CPT_CODE_CHANGE',
    LOOKUP_CHANGE = 'LOOKUP_CHANGE',
    BILLING_TYPE_CHANGE = 'BILLING_TYPE_CHANGE',
    IS_EAP_APPOINTMENT_CHANGE = 'IS_EAP_APPOINTMENT_CHANGE',
    ERA_CORRECTION = 'ERA_CORRECTION',
    SELF_PAY = 'SELF_PAY',
    FORCE_PATIENT_RESPONSIBILITY_RECALCULATION = 'FORCE_PATIENT_RESPONSIBILITY_RECALCULATION',
    SERVICE_LOCATION_CHANGE = 'SERVICE_LOCATION_CHANGE',
    INITIAL_CANCELLATION = 'INITIAL_CANCELLATION',
    PROVIDER_CANCELLATION_FEE_REDUCTION = 'PROVIDER_CANCELLATION_FEE_REDUCTION',
    ADMIN_CANCELLATION_FEE_REDUCTION = 'ADMIN_CANCELLATION_FEE_REDUCTION',
    CANCELLATION_REVERSAL = 'CANCELLATION_REVERSAL',
    AUTOMATED_CHARGE = 'AUTOMATED_CHARGE',
    MANUAL_CHARGE = 'MANUAL_CHARGE',
    EXTERNAL_PAYMENT = 'EXTERNAL_PAYMENT',
    AUTOMATED_REFUND = 'AUTOMATED_REFUND',
    MANUAL_REFUND = 'MANUAL_REFUND',
    APPOINTMENT_UNCONFIRMATION = 'APPOINTMENT_UNCONFIRMATION',
    STRIPE_WEBHOOK = 'STRIPE_WEBHOOK',
    SYNCHRONOUS_STRIPE_RESPONSE = 'SYNCHRONOUS_STRIPE_RESPONSE',
    MANUAL_NEGATION = 'MANUAL_NEGATION',
    BULK_APPOINTMENT_REFUND = 'BULK_APPOINTMENT_REFUND',
    SINGLE_APPOINTMENT_REFUND = 'SINGLE_APPOINTMENT_REFUND',
    CHARGE_PATIENT_TOOL = 'CHARGE_PATIENT_TOOL',
    NEGATIVE_BALANCE_ADJUSTMENT = 'NEGATIVE_BALANCE_ADJUSTMENT',
    FIX_INCORRECT_LINE_ITEM_AMOUNT = 'FIX_INCORRECT_LINE_ITEM_AMOUNT',
    RESPONSIBILITY_ADJUSTMENT = 'RESPONSIBILITY_ADJUSTMENT',
    PATIENT_RESPONSIBILITY_CORRECTIONS_FLOW = 'PATIENT_RESPONSIBILITY_CORRECTIONS_FLOW',
    CX_RECOUP_BALANCE_WRITEOFF = 'CX_RECOUP_BALANCE_WRITEOFF',
    INVOICE_MIGRATION_WRITEOFF = 'INVOICE_MIGRATION_WRITEOFF',
    BULK_REFUND_WRITEOFF = 'BULK_REFUND_WRITEOFF',
    PRELIM_PRICING_WRITEOFF = 'PRELIM_PRICING_WRITEOFF',
    YEAR_END_WRITEOFF = 'YEAR_END_WRITEOFF',
    QUARTER_CLOSE_WRITEOFF = 'QUARTER_CLOSE_WRITEOFF',
    BULK_CANCELLATION_FEE_WRITEOFF = 'BULK_CANCELLATION_FEE_WRITEOFF',
    OLD_PAYMENT_FAILURE_WRITEOFF = 'OLD_PAYMENT_FAILURE_WRITEOFF',
    PROVIDER_INCENTIVE_PAYOUT = 'PROVIDER_INCENTIVE_PAYOUT',
    PROVIDER_INCENTIVE_COMPLETION = 'PROVIDER_INCENTIVE_COMPLETION',
    PROVIDER_AUTOMATIC_PAYOUT = 'PROVIDER_AUTOMATIC_PAYOUT',
    PROVIDER_MANUAL_PAYOUT = 'PROVIDER_MANUAL_PAYOUT',
    INVOICES_BACKFILL = 'INVOICES_BACKFILL'
}


export const InvoiceLineItemSourceMetadata: EnumMetadata<InvoiceLineItemSource> = {
    name: 'InvoiceLineItemSource',
    values: {
            INITIAL_CONFIRMATION: 'INITIAL_CONFIRMATION',
            CPT_CODE_CHANGE: 'CPT_CODE_CHANGE',
            LOOKUP_CHANGE: 'LOOKUP_CHANGE',
            BILLING_TYPE_CHANGE: 'BILLING_TYPE_CHANGE',
            IS_EAP_APPOINTMENT_CHANGE: 'IS_EAP_APPOINTMENT_CHANGE',
            ERA_CORRECTION: 'ERA_CORRECTION',
            SELF_PAY: 'SELF_PAY',
            FORCE_PATIENT_RESPONSIBILITY_RECALCULATION: 'FORCE_PATIENT_RESPONSIBILITY_RECALCULATION',
            SERVICE_LOCATION_CHANGE: 'SERVICE_LOCATION_CHANGE',
            INITIAL_CANCELLATION: 'INITIAL_CANCELLATION',
            PROVIDER_CANCELLATION_FEE_REDUCTION: 'PROVIDER_CANCELLATION_FEE_REDUCTION',
            ADMIN_CANCELLATION_FEE_REDUCTION: 'ADMIN_CANCELLATION_FEE_REDUCTION',
            CANCELLATION_REVERSAL: 'CANCELLATION_REVERSAL',
            AUTOMATED_CHARGE: 'AUTOMATED_CHARGE',
            MANUAL_CHARGE: 'MANUAL_CHARGE',
            EXTERNAL_PAYMENT: 'EXTERNAL_PAYMENT',
            AUTOMATED_REFUND: 'AUTOMATED_REFUND',
            MANUAL_REFUND: 'MANUAL_REFUND',
            APPOINTMENT_UNCONFIRMATION: 'APPOINTMENT_UNCONFIRMATION',
            STRIPE_WEBHOOK: 'STRIPE_WEBHOOK',
            SYNCHRONOUS_STRIPE_RESPONSE: 'SYNCHRONOUS_STRIPE_RESPONSE',
            MANUAL_NEGATION: 'MANUAL_NEGATION',
            BULK_APPOINTMENT_REFUND: 'BULK_APPOINTMENT_REFUND',
            SINGLE_APPOINTMENT_REFUND: 'SINGLE_APPOINTMENT_REFUND',
            CHARGE_PATIENT_TOOL: 'CHARGE_PATIENT_TOOL',
            NEGATIVE_BALANCE_ADJUSTMENT: 'NEGATIVE_BALANCE_ADJUSTMENT',
            FIX_INCORRECT_LINE_ITEM_AMOUNT: 'FIX_INCORRECT_LINE_ITEM_AMOUNT',
            RESPONSIBILITY_ADJUSTMENT: 'RESPONSIBILITY_ADJUSTMENT',
            PATIENT_RESPONSIBILITY_CORRECTIONS_FLOW: 'PATIENT_RESPONSIBILITY_CORRECTIONS_FLOW',
            CX_RECOUP_BALANCE_WRITEOFF: 'CX_RECOUP_BALANCE_WRITEOFF',
            INVOICE_MIGRATION_WRITEOFF: 'INVOICE_MIGRATION_WRITEOFF',
            BULK_REFUND_WRITEOFF: 'BULK_REFUND_WRITEOFF',
            PRELIM_PRICING_WRITEOFF: 'PRELIM_PRICING_WRITEOFF',
            YEAR_END_WRITEOFF: 'YEAR_END_WRITEOFF',
            QUARTER_CLOSE_WRITEOFF: 'QUARTER_CLOSE_WRITEOFF',
            BULK_CANCELLATION_FEE_WRITEOFF: 'BULK_CANCELLATION_FEE_WRITEOFF',
            OLD_PAYMENT_FAILURE_WRITEOFF: 'OLD_PAYMENT_FAILURE_WRITEOFF',
            PROVIDER_INCENTIVE_PAYOUT: 'PROVIDER_INCENTIVE_PAYOUT',
            PROVIDER_INCENTIVE_COMPLETION: 'PROVIDER_INCENTIVE_COMPLETION',
            PROVIDER_AUTOMATIC_PAYOUT: 'PROVIDER_AUTOMATIC_PAYOUT',
            PROVIDER_MANUAL_PAYOUT: 'PROVIDER_MANUAL_PAYOUT',
            INVOICES_BACKFILL: 'INVOICES_BACKFILL'
    }
}
