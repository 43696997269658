import moment from 'moment';

import { InvoiceLineItemSource } from '@headway/api/models/InvoiceLineItemSource';
import { InvoiceLineItemType } from '@headway/api/models/InvoiceLineItemType';
import { LineItemBase } from '@headway/api/models/LineItemBase';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { SortOrder } from '@headway/shared/constants/utils';
import { EventManagerProviderEventRead } from '@headway/shared/events/EventsManagerTypes';

export const getOutstandingBalanceForEvent = (
  event: ProviderEventRead
): number => {
  const appointment = event.providerAppointment;

  const patientInvoiceTotals = appointment?.patientInvoice?.totals;

  if (!patientInvoiceTotals) {
    throw ReferenceError(
      'Event must have patient invoice totals to calculate outstanding balance'
    );
  }

  return patientInvoiceTotals?.balanceCents
    ? patientInvoiceTotals?.balanceCents / 100
    : 0;
};

export const getAutopayChargeDateForEvent = (
  event: ProviderEventRead
): string | undefined =>
  event.providerAppointment?.patientInvoice?.autopayChargeDate;

export const sortAppointmentEventsByDate = (
  appointmentEvents: ProviderEventRead[],
  sortOrder: SortOrder = SortOrder.ASC
): ProviderEventRead[] => {
  return appointmentEvents.sort((a, b) => {
    return sortOrder === SortOrder.DESC
      ? moment(b.createdOn).diff(moment(a.createdOn))
      : moment(a.createdOn).diff(moment(b.createdOn));
  });
};

export const sortAppointmentInvoiceLineItemsByDate = (
  lineItems: LineItemBase[],
  sortOrder: SortOrder = SortOrder.ASC
): LineItemBase[] => {
  return lineItems.sort((a, b) => {
    return sortOrder === SortOrder.DESC
      ? moment(b.createdOn).diff(moment(a.createdOn))
      : moment(a.createdOn).diff(moment(b.createdOn));
  });
};

export const getOutstandingBalanceForEvents = (
  events: ProviderEventRead[]
): number => {
  return events.reduce((total, event) => {
    return total + getOutstandingBalanceForEvent(event);
  }, 0);
};

export const getMostRecentRecoupLineItem = (
  event: ProviderEventRead
): LineItemBase | undefined => {
  const sortedRecoupLineItems = sortAppointmentInvoiceLineItemsByDate(
    event.providerAppointment?.patientInvoice?.lineItems ?? [],
    SortOrder.DESC
  );

  return sortedRecoupLineItems.find(
    (lineItem) =>
      lineItem.type ===
        InvoiceLineItemType.PATIENT_RESPONSIBILITY_CALCULATION &&
      lineItem.source === InvoiceLineItemSource.ERA_CORRECTION &&
      lineItem.amountCents > 0
  );
};

export const getMostRecentRefundLineItem = (
  event: ProviderEventRead
): LineItemBase | undefined => {
  const sortedRecoupLineItems = sortAppointmentInvoiceLineItemsByDate(
    event.providerAppointment?.patientInvoice?.lineItems ?? [],
    SortOrder.DESC
  );

  return sortedRecoupLineItems.find(
    (lineItem) =>
      lineItem.type ===
        InvoiceLineItemType.PATIENT_RESPONSIBILITY_CALCULATION &&
      lineItem.source === InvoiceLineItemSource.ERA_CORRECTION &&
      lineItem.amountCents < 0
  );
};

const getOldestOpenRecoupEvent = (
  allRecoupEvents: ProviderEventRead[]
): ProviderEventRead | null => {
  const openRecoupEvents = allRecoupEvents.filter(
    (event) => getOutstandingBalanceForEvent(event) > 0
  );
  return openRecoupEvents?.length
    ? openRecoupEvents.sort((a, b) => {
        const aCreatedDate = getMostRecentRecoupLineItem(a)?.createdOn;
        const bCreatedDate = getMostRecentRecoupLineItem(b)?.createdOn;
        if (!aCreatedDate && !bCreatedDate) {
          return 0;
        }
        if (!aCreatedDate) {
          return -1;
        }
        if (!bCreatedDate) {
          return 1;
        }
        return moment(aCreatedDate).diff(moment(bCreatedDate));
      })[0]
    : null;
};

export const getMostRecentRecoupEvent = (recoupEvents: ProviderEventRead[]) =>
  recoupEvents?.length
    ? recoupEvents.sort((a, b) => {
        const aCreatedDate = getMostRecentRecoupLineItem(a)?.createdOn;
        const bCreatedDate = getMostRecentRecoupLineItem(b)?.createdOn;
        if (!aCreatedDate && !bCreatedDate) {
          return 0;
        }
        if (!aCreatedDate) {
          return -1;
        }
        if (!bCreatedDate) {
          return 1;
        }
        return moment(bCreatedDate).diff(moment(aCreatedDate));
      })[0]
    : null;

export const getRecoupBenefitCorrectionReason = (
  recoupEvent: ProviderEventRead
) => {
  const mostRecentRecoupLineItem = getMostRecentRecoupLineItem(recoupEvent);
  return mostRecentRecoupLineItem?.publicAdditionalDetails
    ?.benefitCorrectionReason;
};

export const getOutstandingBalanceDueDate = (
  recoupEvents: ProviderEventRead[]
): Date | null => {
  const oldestOpenRecoupEvent = getOldestOpenRecoupEvent(recoupEvents);
  const recoupCreatedDate = oldestOpenRecoupEvent
    ? getMostRecentRecoupLineItem(oldestOpenRecoupEvent)?.createdOn
    : null;
  return recoupCreatedDate
    ? moment(recoupCreatedDate).startOf('day').add(30, 'days').toDate()
    : null;
};

export const getOutstandingBalanceDaysPastDue = (
  recoupEvents: ProviderEventRead[]
): number => {
  const dueDate = getOutstandingBalanceDueDate(recoupEvents);
  return dueDate && dueDate < new Date() ? moment().diff(dueDate, 'days') : 0;
};

export const didEventPriceChange = (
  event: EventManagerProviderEventRead
): boolean => {
  return getEventPriceChange(event) !== 0;
};

// Compares current estimated price to the original estimated price
// Returns -1 if estimated price is less than original, 1 if greater than, 0 if the two are equal
export const getEventPriceChange = (event: EventManagerProviderEventRead) => {
  if (!event.original_estimated_price || !event.estimated_price) {
    return;
  }
  const originalAvg = Math.ceil(
    (event.original_estimated_price.max + event.original_estimated_price.min) /
      2
  );
  const currAvg = Math.ceil(
    (event.estimated_price.max + event.estimated_price.min) / 2
  );
  if (originalAvg !== currAvg) {
    return currAvg > originalAvg ? 1 : -1;
  }

  if (event.estimated_price.max > event.original_estimated_price.max) {
    return 1;
  }
  if (event.estimated_price.max < event.original_estimated_price.max) {
    return -1;
  }
  return 0;
};
